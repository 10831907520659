import * as article from './article'
import * as advert from './advert'
import * as uniform from './uniform'
import * as gift from './gift'

export default {
  article,
  advert,
  uniform,
  gift
}
