import { useHttp, type HttpOption } from '../composables/useHttp'

enum Api {
  Business_Accounting_NO = '/uniform/9D17AE0D-09B5-4732-A8F4-81ADED04B679',
  Branch_Office_Business_Accounting_NO = '/uniform/DC9AC6C1-38CC-479A-A492-088BD8C3328E'
}

// 查詢統一編號總公司
export function checkBusinessAccountingNo(no: string, option?: HttpOption<any>) {
  return useHttp.get<any>(
    Api.Business_Accounting_NO,
    {
      $format: 'json',
      $filter: `Business_Accounting_NO eq ${no}`,
      $skip: 0,
      $top: 1
    },
    option
  )
}

// 查詢統一編號子公司
export function checkBranchOfficeBusinessAccountingNO(no: string, option?: HttpOption<any>) {
  return useHttp.get<any>(
    Api.Branch_Office_Business_Accounting_NO,
    {
      $format: 'json',
      $filter: `Branch_Office_Business_Accounting_NO eq ${no}`,
      $skip: 0,
      $top: 1
    },
    option
  )
}
