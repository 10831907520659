import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IArticleFilterParams } from './types/filter'
import type { IImage } from './types/image'
import type { ILinks, IMeta, ISeo } from './types/response'
import type { ITag } from './types/tag'

export interface IBlock {
  type: string
  data: any
}

export interface IArticleCategory {
  id: string
  subject: string
  en_subject: string
  image: IImage
  description: string
  parent_id: string
  children: IArticleCategory[]
  seo: ISeo
}
export interface IArticle {
  id: number
  subject: string
  publish_date: string
  description: string
  categories: IArticleCategory[]
  image: IImage
  blocks: IBlock[]
  tags: ITag[]
  status: boolean
  seo: ISeo
}

export interface IArticlesReslut {
  top: IArticle[]
  list: {
    data: IArticle[]
    meta: IMeta
    links: ILinks
  }
}

export interface IAuthor {
  id: number
  name: string
  description: string
  content: string
  email: string
  social: Array<{
    name: string
    url: string
  }>
  image: IImage
  seo: ISeo
}

export interface IArticleEcoResult {
  subject: string
  description: string
}

enum Api {
  articleCategories = 'article_categories',
  articles = '/articles',
  articleAuthor = '/article_authors/:id',
  articleEcolifes = '/article_ecolifes',
  articleEcolife = '/article_ecolifes/:id',
  articleEcoResults = '/article_eco_results',
  articleRecommends = '/article_recommends/:id'
}

// 取得文章
export function getArticles(filter: IArticleFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IArticlesReslut>(Api.articles, filter, option)
}

// 取得文章作者
export function getArticleAuthor(id: string, option?: HttpOption<any>) {
  return useHttp.get<IAuthor>(Api.articleAuthor.replace(':id', id), option)
}

// 取得永續發展文章列表
export function getArticleEcolifes(filter: IArticleFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IArticlesReslut>(Api.articleEcolifes, filter, option)
}

// 取得永續發展文章
export function getArticleEcolife(id: string, option?: HttpOption<any>) {
  return useHttp.get<IArticle>(Api.articleEcolife.replace(':id', id), option)
}

// 取得永續發展生活成果列表
export function getArticleEcoResults(filter = {} as IArticleFilterParams, option?: HttpOption<any>) {
  return useHttp.get<IArticleEcoResult[]>(Api.articleEcoResults, filter, option)
}

// 取得文章分類
export function getArticleCategories(type: string, option?: HttpOption<any>) {
  return useHttp.get<IArticleCategory[]>(Api.articleCategories, { class: type }, option)
}

// 取得文章推薦
export function getArticleRecommends(id: string, option?: HttpOption<any>) {
  return useHttp.get<IArticle[]>(Api.articleRecommends.replace(':id', id), option)
}
