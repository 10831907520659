import { useHttp, type HttpOption } from '../composables/useHttp'

enum Api {
  checkGiftInvoice = '/check_gift_invoice'
}

// 查詢統一編號總公司
export function checkGiftInvoiceUsed(giftEventId: any, invoiceNumber: string, option?: HttpOption<any>) {
  return useHttp.get<any>(
    Api.checkGiftInvoice,
    {
      gift_event_id: giftEventId,
      invoice: invoiceNumber
    },
    option
  )
}
