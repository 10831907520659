import { useHttp, type HttpOption } from '../composables/useHttp'
import type { IImage } from './types/image'

export interface IAdvert {
  id: number
  name: string
  type: string
  title: string
  text: string
  image: IImage
  url: string
}

enum Api {
  advert = '/adverts/:type'
}

// 取得廣告
export function getAdvert(type: string, option?: HttpOption<any>) {
  return useHttp.get<IAdvert>(Api.advert.replace(':type', type), option)
}
